<div class="y3-margin-top-24 y3-padding-left-16 y3-padding-right-16" *ngIf="currentTurnover">

  <div class=" y3-flex-row-wrap">
    <div *ngFor="let year of years " class="y3-margin-bottom-16 y3-flex-row-wrap y3-margin-right-8">
      <div>
        <app-button style="padding: 8px" [ngStyle]="{'border' : (currentYear === year) ? '1px solid black' : '0px solid black' }" [type]="'ternary'" [text]="year" (debounceClick)="getTurnover(year)"></app-button>
      </div>
    </div>
  </div>

  <div><strong>Turnover : </strong> {{currentTurnover.totalTurnover.toFixed(2)}} €</div>
  <div class="y3-margin-bottom-16"><strong>Turnover HT : </strong>{{currentTurnover.totalTurnoverWithoutVat.toFixed(2)}} €</div>

  <table class="y3-X-small">
    <tr>
      <th style="text-align: left">EMAIL</th>
      <th >TURNOVER</th>
      <th>TURNOVER WITHOUT VAT</th>

      <th>01</th>
      <th>02</th>
      <th>03</th>
      <th>04</th>
      <th>05</th>
      <th>06</th>
      <th>07</th>
      <th>08</th>
      <th>09</th>
      <th>10</th>
      <th>11</th>
      <th>12</th>
    </tr>
    <tr *ngFor="let customer of currentTurnover.customerStatList" >
      <td style="text-align: left">{{customer.customerEmail}}</td>

      <td>{{customer.totalTurnover.toFixed(2)}}</td>
      <td>{{customer.totalTurnoverWithoutVat.toFixed(2)}}</td>

      <td>{{getTurnoverCustomer(customer, "1") == 0 ? "" :getTurnoverCustomer(customer, "1").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "2") == 0 ? "" :getTurnoverCustomer(customer, "2").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "3") == 0 ? "" :getTurnoverCustomer(customer, "3").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "4") == 0 ? "" :getTurnoverCustomer(customer, "4").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "5") == 0 ? "" :getTurnoverCustomer(customer, "5").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "6") == 0 ? "" :getTurnoverCustomer(customer, "6").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "7") == 0 ? "" :getTurnoverCustomer(customer, "7").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "8") == 0 ? "" :getTurnoverCustomer(customer, "8").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "9") == 0 ? "" :getTurnoverCustomer(customer, "9").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "10") == 0 ? "" :getTurnoverCustomer(customer, "10").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "11") == 0 ? "" :getTurnoverCustomer(customer, "11").toFixed(2)}}</td>
      <td>{{getTurnoverCustomer(customer, "12") == 0 ? "" : getTurnoverCustomer(customer, "12").toFixed(2)}}</td>
    </tr>
  </table>

</div>

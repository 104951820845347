<div class="y3-margin-top-24 y3-padding-left-16 y3-padding-right-16" *ngIf="pmsPerf">

  <div class=" y3-flex-row-wrap">
    <div *ngFor="let month of months " class="y3-margin-bottom-16 y3-flex-row-wrap y3-margin-right-8">
      <div>
        <app-button style="padding: 8px" [ngStyle]="{'border' : (currentMonth === month) ? '1px solid black' : '0px solid black' }" [type]="'ternary'" [text]="month" (debounceClick)="getPmsPerf(month)"></app-button>
      </div>
    </div>
  </div>

  <div class="y3-margin-bottom-16">
    <table>
      <tr>
        <th>DATE</th>
        <th style="text-align: left">PMS</th>
        <th>PROPERTY OWNER DETAILS</th>
      </tr>
      <tr *ngFor="let pmsProperty of pmsPerf; let i = index" >
        <td>{{ pmsProperty.calculationDate }}</td>
        <td style="text-align: left; font-weight: bold">{{ pmsProperty.pmsName }}</td>
        <table>
          <tr style="font-weight: bold" >
            <td>EMAIL</td>
            <td>OTA ACCOUNT ID</td>
            <td>EXCLUDE REV SHARE</td>
            <td>SUBSCRIPTION</td>
            <td>PROMOTION</td>
            <td>TOTAL PMS</td>
            <td>ACTIVE</td>
            <td>INACTIVE</td>
          </tr>
          <tr class="class-tr" *ngFor="let pmsCorporate of pmsProperty.corporateProperties; let j = index" [ngStyle]="{'color' : (pmsCorporate.excludeFromRevShare) ? 'indianred': 'black' }">
            <td style="text-align: left">{{ pmsCorporate.personEmail }}</td>
            <td style="text-align: left">{{ pmsCorporate.otaAccountId }}</td>
            <td style="text-align: left">{{ pmsCorporate.excludeFromRevShare }}</td>
            <td style="text-align: left">{{ pmsCorporate.subscriptionName }}</td>
            <td style="text-align: left">{{ pmsCorporate.promotionCode }}</td>
            <td style="text-align: right">{{ pmsCorporate.numTotalPmsProperties }}</td>
            <td style="text-align: right">{{ pmsCorporate.numActiveProperties }}</td>
            <td style="text-align: right">{{ pmsCorporate.numInactiveProperties }}</td>
        </table>
      </tr>
    </table>
  </div>

  <div class="y3-margin-bottom-16">
    <table>
      <tr>
        <th style="text-align: left">DATE</th>
        <th style="text-align: left">PMS NAME</th>
        <th style="text-align: right">TOTAL CORPORATE-OWNERS</th>
        <th style="text-align: right">TOTAL PROPERTIES</th>
        <th style="text-align: right">ACTIVE PROPERTIES</th>
        <th style="text-align: right">INACTIVES PROPERTIES</th>

      </tr>
      <tr class="class-tr" *ngFor="let pmsProperty of pmsPerf; let i = index" >
        <td style="text-align: left">{{pmsProperty.calculationDate}}</td>
        <td style="text-align: left">{{pmsProperty.pmsName}}</td>
        <td style="text-align: right">{{pmsProperty.totalNumberOfCorporateOwners}}</td>
        <td style="text-align: right">{{pmsProperty.totalProperties}}</td>
        <td style="text-align: right">{{pmsProperty.totalActiveProperties}}</td>
        <td style="text-align: right">{{pmsProperty.totalInactiveProperties}}</td>
      </tr>
    </table>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import {CustomerStat, StripeCustomerTurnover} from "../../model/V4_Stripe_Turnover/StripeCustomerTurnover";
import {AdminService} from "../../core/services/admin.service";
import {UtilService} from "../../core/services/UtilService";

@Component({
  selector: 'app-m13-yaago-turnover',
  templateUrl: './m13-yaago-turnover.component.html',
  styleUrls: ['./m13-yaago-turnover.component.scss']
})
export class M13YaagoTurnoverComponent implements OnInit {

  years: string[] = [];
  currentYear: string = moment(new Date()).format('YYYY');
  currentTurnover: StripeCustomerTurnover;
  constructor(private adminService: AdminService, public utilService: UtilService) { }

  ngOnInit() {
    this.years.push(moment(new Date()).format('YYYY'));
    for (let i = 1; i < 3; i++) {
      // @ts-ignore
      this.years.push(moment(new Date()).subtract(i, 'years').format('YYYY'));
    }
    this.getTurnover(this.currentYear);
  }

  getTurnover(year: string) {
    this.currentYear = year;
    this.adminService.getStripeCustomerTurnover(year).subscribe(data => {
      this.currentTurnover = data;
    }, err => {

    });
  }

  getTurnoverCustomer(stat: CustomerStat, month: string) {
    let turnover = 0;
      if (stat.monthlyTurnoverList.length > 0) {
        stat.monthlyTurnoverList.forEach(monthStat => {
          if (monthStat.month === month) {
            turnover = monthStat.turnover;
          }
        });
    }
    return turnover;

}

}

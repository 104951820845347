<nav>
  <a [routerLink]="'/stats'">|||</a>
  <a [routerLink]="'/users-list'">USER LIST</a>
  <a [routerLink]="'/yaago-dash'">DASH-TECH</a>
  <a [routerLink]="'/yaago-academy'">ACADEMY</a>
  <a [routerLink]="'/tags'">TAGS</a>
  <a [routerLink]="'/permissions'">PERMissions</a>
  <a [routerLink]="'/roles'">ROLES</a>
  <a [routerLink]="'/map'">MAP</a>
  <a [routerLink]="'/yaago-translate'">TRANSLATE</a>
  <a [routerLink]="'/yaago-ota'">OTA</a>
  <a *ngIf="adminKey.startsWith('super')" [routerLink]="'/yaago-perf'">PERF</a>
  <a *ngIf="adminKey.startsWith('super')" [routerLink]="'/yaago-pms'">PMS</a>
  <a [routerLink]="'/yaago-upsells'">UPSELLS</a>
  <a *ngIf="adminKey.startsWith('super')" [routerLink]="'/yaago-turnover'">CA</a>
  <a [routerLink]="'/yaago-refer'">REFER</a>
  <a [routerLink]="'/yaago-airbnb-error'">AIRBNB err</a>

  <div class="animation start-home"></div>
</nav>
